import moment from "moment";
import configHelper from "@/libs/configHelper";

const slugify = (txt) => {
  let str = txt;
  str = str.replace(/^\s+|\s+$/g, "");

  // Make the string lowercase
  str = str.toLowerCase();

  // Remove accents, swap ñ for n, etc
  const from = "ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽĹĽľĺáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·/_,:;";
  const to = "AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZLLllaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa______";
  for (let i = 0, l = from.length; i < l; i += 1) {
    str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }

  // Remove invalid chars
  str = str
    .replace(/[^a-z0-9 _]/g, "")
    // Collapse whitespace and replace by _
    .replace(/\s+/g, "_")
    // Collapse dashes
    .replace(/-+/g, "_");

  return str;
};

const slugifyURL = (txt) => {
  let str = txt;
  str = str.replace(/^\s+|\s+$/g, "");

  // Make the string lowercase
  str = str.toLowerCase();

  // Remove accents, swap ñ for n, etc
  const from = "ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽĹĽľĺáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·-,:;/";
  const to = "AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZLLllaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa----- ";
  for (let i = 0, l = from.length; i < l; i += 1) {
    str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }

  // Remove invalid chars
  str = str
    .replace(/[^a-z0-9 -/]/g, "")
    // Collapse whitespace and replace by _
    .replace(/\s+/g, "-")
    // Collapse dashes
    .replace(/-+/g, "-");

  return str;
};

const replaceNewLines = (txt) => {
  txt = txt.replace(/\s{2,}/g, " ");
  txt = txt.replace(/\t/g, " ");
  txt = txt
    .toString()
    .trim()
    .replace(/(\r\n|\n|\r)/g, "");

  return txt;
};

const generateRandomString = (length) => {
  let result = "";
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

const showToast = (toast, toastComponent, title, text, variant) => {
  toast({
    component: toastComponent,
    position: "bottom-right",
    rtl: true,
    props: {
      title,
      text,
      variant,
    },
  });
};

const getAddUrl = (root, locale = null) => {
  let url = `/add-${root}`;
  if (locale) {
    url = `${url}?translation=${locale}`;
  }
  return url;
};

const getDetailUrl = (root, id, locale = null) => {
  let url = `/${root}s/${id}`;
  if (locale) {
    url = `${url}?translation=${locale}`;
  }
  return url;
};

const getEditUrl = (root, id, locale = null) => {
  let url = `/${root}s/${id}/edit`;
  if (locale) {
    url = `${url}?translation=${locale}`;
  }
  return url;
};

const priceFormat = async (definitions, num, decimal = 0, hideCurrency = false, currencyOverride = null) => {
  let currency = "";
  let def = null;
  if (!hideCurrency) {
    const defaultPriceDefinition = localStorage.getItem("sellio_default_shop_definition");
    if (currencyOverride) {
      def = definitions.find((d) => d.currency.id === currencyOverride);
    } else {
      def = definitions.find((d) => d.identifier === defaultPriceDefinition);
    }
    if (def) {
      currency = " " + def.currency.label;
    } else {
      currency = " " + currencyOverride;
    }
  }
  if (num) {
    let temp = parseFloat(num);
    if (decimal) {
      temp = temp.toFixed(decimal);
    }
    return temp.toString().replace(".", ",") + currency;
  }
  if (num === 0) {
    return parseFloat(num).toFixed(decimal).replace(".", ",") + currency;
  }
  return num;
};

const taxFormat = (num, decimal = 0) => {
  if (num) {
    let temp = parseFloat(num) * 100;
    if (decimal) {
      temp = temp.toFixed(decimal);
    }
    return (
      temp
        .toString()
        .replace(".", ",")
        .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " %"
    );
  }
  if (num === 0) {
    return parseFloat(num).toFixed(decimal).replace(".", ",") + " %";
  }
  return num;
};

const formatDate = (date, format = "DD.MM.YYYY") => {
  if (date) {
    return moment(date).format(format);
  }
  return null;
};

const formatTime = (date) => {
  if (date) {
    return moment(date).format("HH:mm");
  }
  return null;
};

const setLocalStorageCacheWithExpiry = (key, value, ttl) => {
  // ttl = ms
  const now = new Date();

  // `item` is an object which contains the original value
  // as well as the time when it's supposed to expire
  const item = {
    value: value,
    expiry: now.getTime() + ttl,
  };
  localStorage.setItem(key, JSON.stringify(item));
};

const getLocalStorageCacheWithExpiry = (key) => {
  const itemStr = localStorage.getItem(key);
  // if the item doesn't exist, return null
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  // compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
};

const clearLocalStorageCache = () => {
  for (let [key] of Object.entries(localStorage)) {
    if (key.startsWith(process.env.VUE_APP_PREFIX)) {
      localStorage.removeItem(key);
    }
  }
};

const buildParamString = (parameters) => {
  if (parameters) {
    return parameters.map((param) => `${param.customParameter.name}: ${param.customParameterItem.name}`).join(", ");
  }
  return "";
};

const getVibratableSearchParamsFromFilter = (filterData) => {
  const params = {};
  let hasOrder = false;
  if (filterData) {
    for (const filter of filterData) {
      if (filter.filterField && filter.filterValue) {
        if (filter.type === "text") {
          if (filter.filterValue.includes(";")) {
            params[`${filter.filterField}[]`] = filter.filterValue.split(";");
          } else {
            params[filter.filterField] = filter.filterValue;
          }
        }
        if (filter.type === "number") {
          const value = filter.filterValue.toString();
          if (value.includes("<=")) {
            params[`${filter.filterField}[lte]`] = value.replaceAll("<=", "");
          } else if (value.includes(">=")) {
            params[`${filter.filterField}[gte]`] = value.replaceAll(">=", "");
          } else if (value.includes("..")) {
            params[`${filter.filterField}[between]`] = value;
          } else if (value.includes(">")) {
            params[`${filter.filterField}[gt]`] = value.replaceAll(">", "");
          } else if (value.includes("<")) {
            params[`${filter.filterField}[lt]`] = value.replaceAll("<", "");
          } else {
            params[filter.filterField] = value;
          }
        }
        if (filter.type === "select") {
          params[filter.filterField] = filter.filterValue.id;
        }
        if (filter.type === "grouped_select") {
          params[filter.filterField] = filter.filterValue.map((fv) => fv.id);
        }
        if (filter.type === "date") {
          params[`${filter.filterField}[after]`] = filter.filterValue;
        }
      }
      if (filter.sort) {
        let sortField = filter.filterField;
        if (sortField == "search") {
          sortField = "name";
        }
        params[`order[${sortField}]`] = filter.sort;
        hasOrder = true;
      }
    }
  }
  if (!hasOrder) {
    params["order[createdAt]"] = "desc";
  }
  return params;
};

const vibratableQuickEdit = (resource, item, field) => {
  const body = {};
  if (field === "prices") {
    body[field] = item[field];
    const defaultPriceDefinition = localStorage.getItem("sellio_default_shop_definition");
    if (
      defaultPriceDefinition &&
      item.prices &&
      Object.prototype.hasOwnProperty.call(item.prices, defaultPriceDefinition)
    ) {
      item.prices[defaultPriceDefinition] = item.price;
      body[field][defaultPriceDefinition] = item.prices[defaultPriceDefinition];
    }
  } else if (field === "stockCount") {
    body[field] = parseInt(item[field]);
  } else {
    body[field] = item[field];
  }

  resource.updateResourceByUrl({ url: item["@id"], body }).then((response) => {
    if (response.status === 200) {
      if (field === "prices") {
        item[`price_edit`] = false;
      } else {
        item[`${field}_edit`] = false;
      }
    }
  });
};

const cleanPayload = (obj) => {
  return Object.fromEntries(
    Object.entries(obj)
      // eslint-disable-next-line no-unused-vars
      .filter(([_, v]) => v !== "")
      .map(([k, v]) => [k, v === Object(v) ? cleanPayload(v) : v])
  );
};

const buildShowOnWebUrl = (resource, path = "", type = null) => {
  let defaultShopURL = localStorage.getItem("sellio_default_shop_url");
  const defaultShopIdentifier = localStorage.getItem("sellio_default_shop_definition");
  const shopLocale = localStorage.getItem("sellio_locale") || "sk"; // TODO - dat realne default

  const webLocales = {
    sk: {
      page: "",
      article: "blog",
      product: "produkty",
    },
    cs: {
      page: "",
      article: "blog",
      product: "produkty",
    },
    en: {
      page: "",
      article: "blog",
      product: "products",
    },
    de: {
      page: "",
      article: "blog",
      product: "produkte",
    },
    hu: {
      page: "",
      article: "blog",
      product: "termekek",
    },
  };

  let p = "";
  if (type) {
    if (webLocales[shopLocale][type]) {
      p = webLocales[shopLocale][type];
    }

    const overrides = configHelper.getShopPathOverrides();
    if (overrides) {
      if (overrides[shopLocale][type]) {
        p = overrides[shopLocale][type];
      }
    }
  }

  let fullPath = `/${path}`;
  if (p.length) {
    fullPath = `/${p}/${path}`;
  }

  // resource = $Shops
  if (!defaultShopURL || !defaultShopIdentifier) {
    const params = {
      pagination: false,
    };
    resource.getCollection({ params }, ["name", "url", "default", "priceDefinition", "language"]).then((response) => {
      if (response.status === 200) {
        const shops = response.data["hydra:member"];
        const def = shops.find((shop) => shop.default);
        if (def) {
          defaultShopURL = def.url;
          localStorage.setItem("sellio_default_shop_url", def.url);
          if (def.priceDefinition) {
            const parts = def.priceDefinition.split("/");
            localStorage.setItem("sellio_default_shop_definition", parts.pop());
          }
          return path ? `https://${defaultShopURL}${fullPath}` : `https://${defaultShopURL}`;
        }
      }
    });
  }
  return path ? `https://${defaultShopURL}${fullPath}` : `https://${defaultShopURL}`;
};

const getStatusColorCoding = () => {
  return [
    {
      status: true,
      color: "#28c76f",
      background: "rgba(40, 199, 111, 0.12) !important",
    },
    {
      status: false,
      color: "#FE1640",
      background: "rgba(254, 22, 64, 0.12) !important",
    },
    {
      status: "SUCCESS",
      color: "#28c76f",
      background: "rgba(40, 199, 111, 0.12) !important",
    },
    {
      status: "DANGER",
      color: "#FE1640",
      background: "rgba(254, 22, 64, 0.12) !important",
    },
    {
      status: "ACTIVE",
      color: "#28c76f",
      background: "rgba(40, 199, 111, 0.12) !important",
    },
    {
      status: "ARCHIVED",
      color: "#D8D6DE",
      background: "rgba(254, 22, 64, 0.12) !important",
    },
    {
      status: "DRAFT",
      color: "#FFDA2C",
      background: "rgba(255, 218, 44, 0.12) !important",
    },
    {
      status: "NOT_LISTED",
      color: "#FE1640",
      background: "rgba(254, 22, 64, 0.12) !important",
    },
    {
      status: "PAYMENT_PENDING",
      color: "#FA0EFF",
      background: "rgba(250, 14, 255, 0.12) !important",
    },
    {
      status: "PAYMENT_FAILED",
      color: "#FFABAB",
      background: "rgba(255, 171, 171, 0.12) !important",
    },
    {
      status: "PAYMENT_REFUNDED",
      color: "#A91212",
      background: "rgba(169, 18, 18, 0.12) !important",
    },
    {
      status: "PAID",
      color: "#8E24AA",
      background: "rgba(142, 36, 170, 0.12) !important",
    },
    {
      status: "SHIPPED",
      color: "#18E184",
      background: "rgba(24, 225, 132, 0.12) !important",
    },
    {
      status: "READY_FOR_PICKUP",
      color: "#F6BF26",
      background: "rgba(246, 191, 38, 0.12) !important",
    },
    {
      status: "CANCELLED",
      color: "#D50000",
      background: "rgba(213, 0, 0, 0.12) !important",
    },
    {
      status: "CLOSED",
      color: "#0B8043",
      background: "rgba(11, 128, 67, 0.12) !important",
    },
    {
      status: "DRAFT",
      color: "#D8D8D8",
      background: "rgba(216, 216, 216, 0.12) !important",
    },
    {
      status: "NEW",
      color: "#039BE5",
      background: "rgba(3, 155, 229, 0.12) !important",
    },
    {
      status: "PROCESSING",
      color: "#3F51B5",
      background: "rgba(63, 81, 181, 0.12) !important",
    },
    {
      status: "AUTHORIZED",
      color: "#FA0EFF",
      background: "rgba(250, 14, 255, 0.12) !important",
    },
    {
      status: "PARTIALLY_PAID",
      color: "#FFDA2C",
      background: "rgba(255, 218, 44, 0.12) !important",
    },
    {
      status: "NEW",
      color: "#039BE5",
      background: "rgba(3, 155, 229, 0.12) !important",
    },
    {
      status: "PARTIALLY_REFUNDED",
      color: "#18E184",
      background: "rgba(24, 225, 132, 0.12) !important",
    },
    {
      status: "REFUNDED",
      color: "#F6BF26",
      background: "rgba(246, 191, 38, 0.12) !important",
    },
    {
      status: "REFUNDED",
      color: "#A91212",
      background: "rgba(169, 18, 18, 0.12) !important",
    },
    {
      status: "PENDING",
      color: "#D8D6DE",
      background: "rgba(216, 214, 222, 0.12) !important",
    },
    {
      status: "VOIDED",
      color: "#0B8043",
      background: "rgba(11, 128, 67, 0.12) !important",
    },
    {
      status: "FAILED",
      color: "#FFABAB",
      background: "rgba(255, 171, 171, 0.12) !important",
    },
    {
      status: "PLANNED",
      color: "#737373",
      background: "rgba(115, 115, 115, 0.12) !important",
    },
  ];
};

const getStatusColor = (status) => {
  const colors = getStatusColorCoding();
  const def = colors.find((c) => c.status === status);
  if (def) {
    return def.color;
  }
  return null;
};

const getStatusBackground = (status) => {
  const colors = getStatusColorCoding();
  const def = colors.find((c) => c.status === status);
  if (def) {
    return def.background;
  }
  return null;
};

const extractLangFromRoute = (route) => {
  if (route.query.translation) {
    const enabledLanguages = configHelper.getShopLanguages();
    if (enabledLanguages.includes(route.query.translation)) {
      return route.query.translation;
    }
  }
  return null;
};

const validatePrices = (value) => {
  for (const prop in value) {
    if (!value[prop].toString().length) {
      return false;
    }
  }
  return true;
};

// Array.fromList = function (list) {
//   var array = new Array(list.length);
//   for (var i = 0, n = list.length; i < n; i++) array[i] = list[i];
//   return array;
// };

// const filterNodes = (element, allow) => {
//   Array.fromList(element.childNodes).forEach(function (child) {
//     if (child.nodeType === 1) {
//       filterNodes(child, allow);

//       var tag = child.tagName.toLowerCase();
//       if (tag in allow) {
//         Array.fromList(child.attributes).forEach(function (attr) {
//           if (allow[tag].indexOf(attr.name.toLowerCase()) === -1) child.removeAttributeNode(attr);
//         });
//       } else {
//         while (child.firstChild) element.insertBefore(child.firstChild, child);
//         element.removeChild(child);
//       }
//     }
//   });
// };

export default {
  slugify,
  slugifyURL,
  replaceNewLines,
  showToast,
  generateRandomString,
  formatDate,
  formatTime,
  getAddUrl,
  getDetailUrl,
  getEditUrl,
  priceFormat,
  taxFormat,
  setLocalStorageCacheWithExpiry,
  getLocalStorageCacheWithExpiry,
  clearLocalStorageCache,
  buildParamString,
  cleanPayload,
  getVibratableSearchParamsFromFilter,
  vibratableQuickEdit,
  buildShowOnWebUrl,
  getStatusColorCoding,
  getStatusColor,
  getStatusBackground,
  extractLangFromRoute,
  validatePrices,
  // filterNodes,
};
