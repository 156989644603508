import shopsDev from "@/configs/shops";
import shopsStage from "@/configs/shops-stage";
import shopsProd from "@/configs/shops-production";

const getShopEnvConfig = () => {
  if (process.env.VUE_APP_PROD == "localhost") {
    return shopsDev;
  } else if (process.env.VUE_APP_PROD == "stage") {
    return shopsStage;
  } else if (process.env.VUE_APP_PROD == "production") {
    return shopsProd;
  }
};

const getShopNameFromUrl = () => {
  if (process.env.VUE_APP_PROD == "localhost") {
    const shops = getShopEnvConfig();
    return Object.keys(shops)[0];
  } else {
    return window.location.host;
  }
};

const getShopPagesTemplates = () => {
  const shops = getShopEnvConfig();
  const shop = getShopNameFromUrl();
  if (shop && shops[shop] && shops[shop]["templates"]) {
    return shops[shop]["templates"];
  }
  return null;
};

const getShopBannerTemplates = () => {
  const shops = getShopEnvConfig();
  const shop = getShopNameFromUrl();
  if (shop && shops[shop] && shops[shop]["banner_templates"]) {
    return shops[shop]["banner_templates"];
  }
  return null;
};

const getDefaultLanguage = () => {
  const shops = getShopEnvConfig();
  const shop = getShopNameFromUrl();
  if (shop && shops[shop] && shops[shop]["defaultLanguage"]) {
    return shops[shop]["defaultLanguage"];
  }
  return "sk";
};

const isShopMultilang = () => {
  const shops = getShopEnvConfig();
  const shop = getShopNameFromUrl();
  if (shop && shops[shop] && shops[shop]["languages"] && shops[shop]["languages"].length > 1) {
    return true;
  }
  return false;
};

const getShopLanguages = () => {
  const shops = getShopEnvConfig();
  const shop = getShopNameFromUrl();
  if (shop && shops[shop]) {
    if (shops[shop]["languages"] && shops[shop]["languages"].length) {
      return shops[shop]["languages"];
    } else {
      return ["sk"];
    }
  }
  return false;
};

const getShopCustomActions = (resource = null) => {
  const shops = getShopEnvConfig();
  const shop = getShopNameFromUrl();
  if (shop && shops[shop]) {
    if (shops[shop]["custom_actions"]) {
      if (resource) {
        return shops[shop]["custom_actions"][resource];
      }
      return shops[shop]["custom_actions"];
    }
  }
  return [];
};

const getShopPathOverrides = () => {
  const shops = getShopEnvConfig();
  const shop = getShopNameFromUrl();
  if (shop && shops[shop]) {
    if (shops[shop]["path_overrides"]) {
      return shops[shop]["path_overrides"];
    }
  }
  return null;
};

export default {
  getShopEnvConfig,
  getShopNameFromUrl,
  getShopPagesTemplates,
  getShopBannerTemplates,
  getDefaultLanguage,
  isShopMultilang,
  getShopLanguages,
  getShopCustomActions,
  getShopPathOverrides,
};
