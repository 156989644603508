import Resource from "./Resource";

let repositories = {};

export default {
  init: (JWT, apiURL) => {
    repositories = {
      Articles: new Resource(JWT, { apiURL, resource: "articles" }),
      Banners: new Resource(JWT, { apiURL, resource: "banners" }),
      BannerSlides: new Resource(JWT, { apiURL, resource: "banner_slides" }),
      Blocks: new Resource(JWT, { apiURL, resource: "json_blocks" }),
      Carriers: new Resource(JWT, { apiURL, resource: "carriers" }),
      Categories: new Resource(JWT, { apiURL, resource: "categories" }),
      Configurations: new Resource(JWT, {
        apiURL,
        resource: "product_configurations",
      }),
      Coupons: new Resource(JWT, { apiURL, resource: "coupons" }),
      CustomFields: new Resource(JWT, { apiURL, resource: "custom_fields" }),
      CustomResources: new Resource(JWT, { apiURL, resource: "custom_resources" }),
      Discounts: new Resource(JWT, { apiURL, resource: "discounts" }),
      Enquiries: new Resource(JWT, { apiURL, resource: "enquiries" }),
      Enums: new Resource(JWT, { apiURL, resource: "enums" }),
      Emails: new Resource(JWT, { apiURL, resource: "emails" }),
      EmailTemplates: new Resource(JWT, {
        apiURL,
        resource: "email_templates",
      }),
      Establishments: new Resource(JWT, { apiURL, resource: "establishments" }),
      Exports: new Resource(JWT, { apiURL, resource: "exports" }),
      Files: new Resource(JWT, { apiURL, resource: "files" }),
      Navigations: new Resource(JWT, { apiURL, resource: "navigations" }),
      Orders: new Resource(JWT, { apiURL, resource: "orders" }),
      Parameters: new Resource(JWT, { apiURL, resource: "custom_parameters" }),
      ParameterItems: new Resource(JWT, { apiURL, resource: "custom_parameter_items" }),
      Payments: new Resource(JWT, { apiURL, resource: "payments" }),
      Pdfs: new Resource(JWT, { apiURL, resource: "pdfs" }),
      PickUpPoints: new Resource(JWT, { apiURL, resource: "pick_up_points" }),
      PriceDefinitions: new Resource(JWT, { apiURL, resource: "price_definitions" }),
      PriceLevels: new Resource(JWT, { apiURL, resource: "price_levels" }),
      Producers: new Resource(JWT, { apiURL, resource: "producers" }),
      Products: new Resource(JWT, { apiURL, resource: "products" }),
      ProductInformationParameters: new Resource(JWT, { apiURL, resource: "product_information_parameters" }),
      ProductVariants: new Resource(JWT, {
        apiURL,
        resource: "product_variants",
      }),
      ProductVariantInformationParameters: new Resource(JWT, {
        apiURL,
        resource: "product_variant_information_parameters",
      }),
      ProductQuantityPrices: new Resource(JWT, { apiURL, resource: "product_quantity_prices" }),
      ProductVariantQuantityPrices: new Resource(JWT, { apiURL, resource: "product_variant_quantity_prices" }),
      Reviews: new Resource(JWT, { apiURL, resource: "reviews" }),
      Roles: new Resource(JWT, { apiURL, resource: "roles" }),
      Root: new Resource(JWT, { apiURL, resource: "" }),
      Sets: new Resource(JWT, { apiURL, resource: "product_in_sets" }),
      Shops: new Resource(JWT, { apiURL, resource: "shops" }),
      Sms: new Resource(JWT, { apiURL, resource: "sms" }),
      SmsTemplates: new Resource(JWT, { apiURL, resource: "sms_templates" }),
      Tags: new Resource(JWT, { apiURL, resource: "tags" }),
      Users: new Resource(JWT, { apiURL, resource: "users" }),
      UserGroups: new Resource(JWT, { apiURL, resource: "user_groups" }),
      UserInformationParameters: new Resource(JWT, { apiURL, resource: "user_information_parameters" }),
      Variables: new Resource(JWT, { apiURL, resource: "environment_variables" }),
      WebPages: new Resource(JWT, { apiURL, resource: "web_pages" }),
      WebTranslations: new Resource(JWT, { apiURL, resource: "web_translations" }),
      Zones: new Resource(JWT, { apiURL, resource: "zones" }),
      GoogleProductCategories: new Resource(JWT, { apiURL, resource: "google_product_categories" }),
    };
  },
  get: (name) => repositories[name],
};
